<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1 "
          :loading-text="$t('table.messages.loading')"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 100],
            showFirstLastPage: false,
            'items-per-page-text': $t('table.messages.items_per_page'),
          }"
          @click:row="handleClick"
        >
          <template slot="no-data">
            {{ $t('table.messages.no_data') }}
          </template>
          <template v-slot:top>
            <v-container class="mt-5">
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <span class="forth--text text-h4 font-weight-bold">{{
                      $t('messages.search_text_customer')
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex ">
                <v-col cols="10">
                  <div class="align-self-center mr-9">
                    <v-text-field
                      id="search"
                      v-model="search"
                      name="search pa-0 "
                      outlined
                    />
                  </div>
                </v-col>
              </v-row>

              <v-row class="d-flex">
                <v-col
                  cols="2"
                  class="mr-auto"
                >
                  <v-btn
                    v-if="userType !== 2"
                    dense
                    color="forth"
                    class="mb-5 pt-0 pl-10 pr-10 white--text"
                    @click="$router.push({path: '/customer'})"
                  >
                    <span class="font-weight-bold text-h5">
                      {{ $t('messages.Create') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="userType === 1"
                  cols="2"
                >
                  <v-btn
                    color="forth"
                    class="mb-5 pt-0 white--text btn-import-csv"
                    @click="OnclickImportCSV"
                  >
                    <span class=" font-weight-bold text-h5">
                      {{ $t('messages.import_csv') }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.index="props">
            {{ items.indexOf(props.item) + 1 }}
          </template>
          <template v-slot:item.name="props">
            <router-link :to="'/customer-detail/'+props.item.id">
              {{ props.item.name }}
            </router-link>
          </template>
          <template v-slot:footer.prepend />
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import _ from 'lodash'

  export default {
    name: 'CustomerList',

    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        selected: {},
        singleSelect: true,
        options: {},
        accounts: [],
        headers: [
          {
            text: this.$t('table.headers.index'),
            align: 'start',
            value: 'index',
            class: 'font-weight-bold',
            sortable: false,
            width: '5.5rem',
          },
          {
            text: this.$t('table.headers.manageIdCustomer'),
            align: 'center',
            value: 'code',
            class: 'font-weight-bold',
            sortable: false,
            width: '8.45rem',
          },
          {
            text: this.$t('table.headers.customerName'),
            align: 'start',
            value: 'name',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.personInCharge'),
            align: 'center',
            value: 'inChargedEmployee',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.inputEmployee'),
            align: 'start',
            value: 'inputEmployee',
            class: 'font-weight-bold',
            sortable: false,
            width: '8rem',
          },
          {
            text: this.$t('table.headers.inputCheckEmployee'),
            align: 'start',
            value: 'inputCheckEmployee',
            class: 'font-weight-bold',
            sortable: false,
            width: '8rem',
          },
          {
            text: this.$t('table.headers.paymentCheckEmployee'),
            align: 'start',
            value: 'paymentCheckEmployee',
            class: 'font-weight-bold',
            sortable: false,
            width: '8rem',
          },
          {
            text: this.$t('table.headers.invoiceSendDeadline'),
            align: 'start',
            value: 'invoiceSendDeadline',
            class: 'font-weight-bold',
            sortable: false,
            width: '15rem',
          },
          {
            text: this.$t('table.headers.contactAddress'),
            align: 'start',
            value: 'contactAddress',
            class: 'font-weight-bold',
            sortable: false,
            width: '15rem',
          },
          {
            text: this.$t('table.headers.payday'),
            align: 'start',
            value: 'payday',
            class: 'font-weight-bold',
            sortable: false,
            width: '10rem',
          },
          {
            text: this.$t('table.headers.remarks'),
            align: 'center',
            value: 'note',
            class: 'font-weight-bold',
            sortable: false,
            width: '6rem',
          },
        ],
        editedIndex: -1,
        items: [],
        currentOptions: {},
        search: '',
        loading: false,
        totalItems: 1,
        userType: 1,
      }
    },
    computed: {
      ...get('customer', ['list', 'message', 'status', 'error', 'totalCount', 'statusCode']),
    },
    watch: {
      totalCount (value) {
        this.totalItems = value
      },
      list (value) {
        this.$set(this, 'items', value.data.customers)
        window.scrollTo(0, document.body.scrollHeight)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
      // watch error
      },
    },
    created () {
      const user = JSON.parse(localStorage.getItem('user'))
      const data = JSON.parse(_.get(user, 'data', {}))
      const type = _.get(data, 'type', 0)
      this.userType = type

      if (type !== 1 && type !== 2) {
        this.$router.push('/')
      }
      this.$store.dispatch('customer/getCustomers')
    },
    mounted () {
      if (this.statusCode) {
        this.showSnackBar('messages.' + this.statusCode)
        this.$store.dispatch('customer/updateStatusCodeDefault')
      }
      this.$store.dispatch('customer/getNewPass', '')
    },
    methods: {
      handleClick () {},
      OnclickImportCSV () {
        this.$router.push('/customer-list/import')
      },
      OnclickExportCSV () {},
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
    },
  }
</script>

<style scoped lang="sass">
.btn-import-csv
  margin-right: 36px !important
  float: right
.isAssigned
  color: #4CAF50
.notIsAssigned
  color: #FB8C00
</style>
